@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .bg-white {
        background-color: white;
    }
    .text-white {
        color: white;
    }
    .bg-black {
        background-color: black;
    }
    .text-hover {
        @apply hover:text-[#fff]
    }
    .text-type-1 {
        @apply text-gray-100 
    }
    
  }

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}
.style .slick-arrow.slick-next {
    right: -40px;
}
.style .slick-arrow.slick-prev {
    left: -40px;
}
.brand .slick-slider {
    padding: 40px 0;
}
.brand .slick-prev {
    left: 20px;
    z-index: 99;
}
.brand .slick-next {
    right: 20px;
    z-index: 99;
}