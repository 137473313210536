.container-slider {
  height: 500px;
  position: relative;
  /* overflow: hidden; */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); */
}

.container-slider.banner {
  height: 800px
}

.banner-triangle {
  width: 100%;
  height: 0;
  border-style: solid;
  border-width: 0 0 300px 1920px;
  border-color: transparent transparent #dfe0e0 transparent;
}
.banner-reverse-triangle::before {
  content: '';
  width: 100%;
  height: 0;
  border-style: solid;
  border-width: 0 0 200px 1920px;
  border-color: #dfe0e0 #dfe0e0 transparent #dfe0e0;
  position: absolute;
  left: 0;
  bottom: 0;
  box-shadow: 12px 10px 5px 0px rgba(0,0,0,0.75);
}
.button-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 0 25px;
  border-color: #fff transparent transparent transparent;
}

@media screen and (max-width: 700px) {
  .container-slider {
    height: 400px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;

}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-anim {
  animation: fadeInAnimation ease .5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

}

@keyframes fadeInAnimation {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.btn-slide {
  width: 60px;
  height: 100%;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: .5;
  transition: opacity .2s ease;
}

.btn-slide:hover {
  opacity: .9;
}

.btn-slide img {
  width: 40px;
  height: 40px;
  pointer-events: none;
}

.prev {
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.next {
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

@media screen and (max-width: 900px) {
  .prev {
    left: 20px;
  }

  .next {
    right: 20px;
  }
  .banner .prev {
    left: 0;
  }

  .banner .next {
    right: 0;
  }
}


.container-dots {
  position: absolute;
  bottom: 30px;
  left: 30px;
  transform: translateY(100%);
  display: flex;

}

.banner .container-dots {
  z-index: 99;
  transform: rotate(-4deg);
  right: 30px;
  left: auto;
}
@media screen and (max-width: 1024px) {
  .banner .container-dots {
    bottom: -10px;
  }
}

.dot {
  width: 20px;
  height: 3px;
  opacity: .5;
  transition: opacity .2s ease;
  border: 2px solid #000;
  margin: 0 5px;
  background: #fff;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.banner .container-dots .dot {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  box-shadow: 2px 2px 14px 1px rgb(0 0 0 / 75%);


}

.dot.active {
  opacity: 1;

}
.banner .dot.active {
  background: #a99fa4;

}

.swiper-pointer-events {
  max-width: 800px;
}

.swiper-pagination {
  bottom: 0;
  margin-top: 20px;


}

.swiper-button-prev,
.swiper-button-next {
  transform: translateY(-50%);
}

.txt-container {
  display: none;
  position: absolute;
  bottom: 40px;
  margin-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  transition: display .2s ease;
  text-align: center;
}

.txt-container.active {
  display: block;
}